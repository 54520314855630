body {
  background-color: rgb(255, 255, 255);
  color: rgb(47, 47, 47);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  margin-bottom: 30px;
  margin-top: 25px;
}

* {
  box-sizing: border-box;
}

hr {
  border-color: rgb(217, 217, 217);
  border-style: solid;
}
